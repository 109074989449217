/* eslint-disable max-len */
export default {
  logo: {
    src: 'https://mobicheckin-assets.s3.eu-west-1.amazonaws.com/uploads/events/63ff5b42976ea6006b695226/website/LogoBDAIP-pasCorp-BLANC_977cbf9d-501b-49e9-9c9a-4bb8dc6f9a6d.png',
    alt: 'logo',
    web: 'https://www.bigdataparis.com/',
  },
  frame: {
    src: 'https://mobicheckin-assets.s3.eu-west-1.amazonaws.com/uploads/events/63ff5b42976ea6006b695226/website/assets-folder64ccf030de927e06e4296f30/my-bdaip-selfie_40f90991-07c7-43b8-8dcb-cf3f712d4b2b.png',
    alt: 'frame',
  },
  head: 'Big data & AI Paris présente',
  description:
    "Personnalisez votre photo aux couleurs de Big Data & AI Paris 2023 et partagez-la sur vos réseaux sociaux !",
  subheading: 'Big data & AI Paris présente',
  button: 'Continuer',
};
